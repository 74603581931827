import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Permission } from "src/app/core/enums/permission.enum";
import { API_URL, IDENTITY_URL } from "src/environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";
import { resetPasswordData } from "../interfaces/reset-password-data.interface";

@Injectable({ providedIn: "root" })
export class UsersService {
  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private router: Router
  ) {}

  identityUrl = this.env.getValueTransferState(IDENTITY_URL);
  apiUrl = this.env.getValueTransferState(API_URL);

  preLogin(username: string, password: string) {
    const body = new HttpParams()
      .set("username", username)
      .set("password", password);
  
    return this.http.post<any>(`${this.apiUrl}/app/permissionAdministrator/preLogin`, body.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  login(username: string, password: string, isUserSwitch: boolean, verificationCode: string) {
    let body = new HttpParams()
      .set("client_id", "Maritime_App")
      .set("grant_type", "password")
      .set("username", username)
      .set("password", password)
      .set("isUserSwitch", isUserSwitch);

    if (verificationCode) {
      body = body.set("verificationCode", btoa(verificationCode));
    }

    return this.http.post<any>(`${this.identityUrl}/connect/token`, body.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  sendCode(userName: string) {
    return this.http.post(`${this.apiUrl}/app/permissionAdministrator/sendVerificationCode`, { userName });
  }

  refreshToken(refreshToken: string) {
    const body = new HttpParams()
      .set("client_id", "Maritime_App")
      .set("grant_type", "refresh_token")
      .set("refresh_token", refreshToken);

    return this.http.post<any>(`${this.identityUrl}/connect/token`, body.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  forgotPassword(username: string) {
    return this.http.post<any>(`${this.apiUrl}/app/appUsers/forgotPassword`, { username });
  }

  resetPassword(data: resetPasswordData) {
    return this.http.post<any>(`${this.apiUrl}/app/appUsers/resetPassword`, data);
  }

  redirectAfterLogin(userRole: Permission): void {
    switch (userRole) {
      case Permission.Administrator:
        this.router.navigate(["/admin/ports"]);
        break;

      case Permission.EmployerTimekeeper:
      case Permission.EmployerSupervisor:
      case Permission.SingleStepApprover:
        this.router.navigate(["/timekeeping"]);
        break;

      case Permission.UnionAdministrator:
      case Permission.UnionAdminReadonly:
      case Permission.StevedoringAssociation:
        this.router.navigate(["/labor"]);
        break;

      case Permission.UnionTimekeeper:
        this.router.navigate(["/users/select-employer"]);
        break;

      case Permission.UnionDispatcher:
      case Permission.UnionSupervisor:
      case Permission.EmployerTest:
        this.router.navigate(["/orders"]);
        break;

      case Permission.ReeferMechanic:
        this.router.navigate(["/reefermechanic"]);
        break;

      case Permission.ILAAdmin:
      case Permission.USMXAdmin:
        this.router.navigate(["/reports"]);
        break;
        
      case Permission.EmployerUploads:
        this.router.navigate(["/upload"]);
        break;

      case Permission.CallRecords:
        this.router.navigate(["/recordings"]);
        break;
    }
  }
}
